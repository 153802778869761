










































import { Component, Vue } from "vue-property-decorator"
import ViewWrapper from "@/views/ViewWrapper.vue"
import CareerBannerPage from "@/components/Career/Main/CareerBanner.vue"
import CareerDescriptionPage from "@/components/Career/Main/CareerDescription.vue"
import CareerExecutive from "@/components/Career/Main/CareerExecutive.vue"
import CareerPositionComponent from "@/components/Career/Main/CareerPosition.vue"
import CareerFooterComponent from "@/components/Career/Main/CareerFooter.vue"
// @ts-ignore
import AOS from "aos"

@Component({
  name: "CareerMainPage",
  components: {
    CareerFooterComponent,
    CareerPositionComponent,
    CareerExecutive,
    CareerDescriptionPage,
    CareerBannerPage,
    ViewWrapper
  }
})
export default class CareerMainPage extends Vue {
  // eslint-disable-next-line class-methods-use-this
  mounted() {
    window.addEventListener("load", AOS.refresh)
  }

  // eslint-disable-next-line class-methods-use-this
  toPositionSection() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const elm = document.getElementById("career-position")!
    this.$scrollTo(elm, {
      duration: 800
    })
  }
}
