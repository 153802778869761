import DiamondBaseAPI from "@/api/Api"
import ResponseBase from "@/types/ResponseBase"
import CareerBanner from "@/types/Career/CareerBanner"
import CareerDescription from "@/types/Career/CareerDescription"
import CareerExecutive from "@/types/Career/CareerExecutive"
import Career from "@/types/Career/Career"
import CareerFooter from "@/types/Career/CareerFooter"
import CareerDetail from "@/types/Career/CareerDetail"
import CareerJob from "@/types/Career/CareerJob"
import { serialize } from "typescript-json-serializer"
import _ from "lodash"

export default class CareerApi extends DiamondBaseAPI {
  static getCareerBanner(): Promise<ResponseBase<CareerBanner>> {
    return this.api.get("career-banner").then(({ data }) => data)
  }

  static getCareerDescription(): Promise<ResponseBase<CareerDescription>> {
    return this.api.get("career-description").then(({ data }) => data)
  }

  static listCareerExecutives(): Promise<ResponseBase<CareerExecutive[]>> {
    return this.api.get("career-executive").then(({ data }) => data)
  }

  static listCareers(): Promise<ResponseBase<Career[]>> {
    return this.api.get("career").then(({ data }) => data)
  }

  static getCareerFooter(): Promise<ResponseBase<CareerFooter>> {
    return this.api.get("career-footer").then(({ data }) => data)
  }

  static getCareerDetail(id: number): Promise<ResponseBase<CareerDetail>> {
    return this.api.get(`career/${id}`).then(({ data }) => data)
  }

  static postCareerJob(positionId: number, detail: CareerJob) {
    const body = serialize(detail, false)
    const formData = new FormData()
    _.forEach(body, (value, key) => {
      formData.append(key, value)
    })
    return this.api
      .post(`apply-job/${positionId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then(({ data }) => data)
  }
}
