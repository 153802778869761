






















































import { Component, PropSync } from "vue-property-decorator"
import _ from "lodash"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"
import ModalBase from "@/components/ModalBase.vue"

@Component({
  name: "ModalPolicy",
  components: {
    ModalBase
  }
})
export default class ModalPolicy extends mixins(BreakpointMixin) {
  private checked: boolean = false
  @PropSync("value") isActive!: boolean

  get textTitle1() {
    // return this.$t("global.all") as string
    return "นโยบายการยอมรับ"
  }
  get textTitle2() {
    // return this.$t("global.all") as string
    return "วัตถุประสงค์เพื่อการสมัครงาน"
  }

  get xsScreen() {
    return this.$vuetify.breakpoint.xsOnly
  }

  get buttonWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.mdAndUp) return 268
    return 251
  }

  get buttonHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.mdAndUp) return 60
    return 47
  }

  private get titleFontSize() {
    const bp = this.$vuetify.breakpoint
    let size = 0
    if (bp.xlOnly) size = 40
    else if (bp.lgOnly) size = 24
    else size = 20
    return `${size}px`
  }

  private resetCheck(e: boolean) {
    if (!e) {
      this.checked = false
    }
  }

  private accept() {
    this.$emit("accept", true)
    this.isActive = false
  }
}
