

























































import { Component, Watch } from "vue-property-decorator"
import CareerApi from "@/api/Career/CareerApi"
import { deserialize } from "typescript-json-serializer"
import _ from "lodash"
import Career from "@/types/Career/Career"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"
/** Component */
import ModalPolicy from "@/components/Policy/ModalPolicy.vue"

interface Route {
  name: String
  query: Object
}
@Component({
  name: "CareerPositionComponent",
  components: {
    ModalPolicy
  }
})
export default class CareerPositionComponent extends mixins(BreakpointMixin) {
  private careers: Career[] = []
  private link: Object = {}
  private isActive = false

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    CareerApi.listCareers().then(({ data }) => {
      this.careers = _.map(data, (d) => deserialize<Career>(d, Career))
    })
  }

  mounted() {
    this.fetchData()
  }

  toCareerDetailPage(id: number) {
    this.$router.push({ name: "CareerDetailPage", query: { id: `${id}`, top: "true" } })
  }

  toApplyPage(id: number) {
    this.$router.push({ name: "CareerApplyPage", query: { id: `${id}`, top: "true" } })
  }

  openPolicy(link: Route) {
    this.isActive = true
    this.link = link
  }

  get smallScreen() {
    return this.$vuetify.breakpoint.smAndDown
  }

  get buttonHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.mdAndUp) return 50
    return 34
  }

  private get titleFontSize() {
    const bp = this.$vuetify.breakpoint
    let size = 0
    if (bp.xlOnly) size = 40
    else if (bp.lgOnly) size = 24
    else size = 20
    return `${size}px`
  }

  private generateRoute(model: string, id: number) {
    if (model === "detail") {
      return { name: "CareerDetailPage", query: { id: `${id}`, top: "true" } }
    } else {
      return { name: "CareerApplyPage", query: { id: `${id}`, top: "true" } }
    }
  }

  private accept(e: boolean) {
    if (e) {
      this.$router.push(this.link)
    }
  }
}
