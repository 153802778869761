








import { Component, Vue, Watch } from "vue-property-decorator"
import CareerApi from "@/api/Career/CareerApi"
import { deserialize } from "typescript-json-serializer"
import CareerDescription from "@/types/Career/CareerDescription"

@Component({
  name: "CareerDescriptionComponent"
})
export default class CareerDescriptionComponent extends Vue {
  private description = new CareerDescription()

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    CareerApi.getCareerDescription().then(({ data }) => {
      this.description = deserialize<CareerDescription>(data, CareerDescription)
    })
  }

  mounted() {
    this.fetchData()
  }
}
