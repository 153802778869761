import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class CareerExecutive {
  @JsonProperty("career_executive_id") id?: number

  @JsonProperty() bio?: string

  @JsonProperty() name?: string

  @JsonProperty("posion_th") positionTH?: string

  @JsonProperty("posion_en") positionEN?: string

  @JsonProperty({ type: DiamondFile }) file?: DiamondFile = new DiamondFile()
}
